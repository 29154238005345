import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import { ProductDetail } from "./pages/ProductDetail/ProductDetail";
import { Products } from "./pages/Products/Products";
import { TermsAndConditions } from "./pages/TermsAndConditions/TermsAndConditions";
import { HowToOrder } from "./pages/HowToOrder/HowToOrder";
import FinishOrder from "./pages/FinishOrder/FinishOrder";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
function App() {
  /*TODO:
    

      - Na stránkách vylepšit SEO - použít HELMET viz níže
      - Zjistit jaké metadata vyhodí google search
      - jak zajistit aby na google vypadli kontaktní údaje o firmě      
      - použít npm build a nahrání na server pro lepší SEO a loga - nahrání linků z index.html aby bylo v react snaps
      - zjistit další tipy na SEO
      - přenesení dat do databáze 
      -

      - PROBLÉM: index.html react snaps je blank page po nahrátí .js souboru v build version - otestovat



        <Helmet>
        <title>Obchodní podmínky | Gazar Group</title>
        <meta
          name="description"
          content="Kupující učiněním závazné objednávky stvrzuje, že akceptuje Obchodní podmínky pro dodávku zboží vyhlášené prodávajícím. Vztahy mezi kupujícím a prodávajícím se řídí těmito obchodními podmínkami, které jsou zároveň pro obě strany závazné."
        />
        <meta
          name="keywords"
          content="VŠEOBECNÁ USTANOVENÍ, OBJEDNÁNÍ ZBOŽÍ A SLUŽEB, POTVRZENÍ KOŠÍKU, VYPLŇTE FORMULÁŘ "
        />
        <meta property="og:title" content="Jak u nás nakupovat | Gazar Group" />
        <meta
          property="og:description"
          content="Naše stránka funguje primárně jako portfolio produktů, které můžete
            poptat. Vámi poptané zboží zpracujeme do cenové nabídky a dohodneme
            platební metodu."
        />
        <meta
          property="og:image"
          content="https://gazargroup.com/logo192.png"
        />
        <meta property="og:url" content={`https://gazargroup.com/shopguide`} />
        <meta
          name="twitter:title"
          content={`Jak u nás nakupovat | Gazar Group`}
        />
        <meta
          name="twitter:description"
          content="Naše stránka funguje primárně jako portfolio produktů, které můžete
            poptat. Vámi poptané zboží zpracujeme do cenové nabídky a dohodneme
            platební metodu."
        />
        <meta
          name="twitter:image"
          content="https://gazargroup.com/logo192.png"
        />
      </Helmet>
   

  */

  return (
    <div className="appWrapper">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Products />} />
          <Route path="/aboutus" element={<Home />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/checkout" element={<FinishOrder />} />
          <Route path="/shopguide" element={<HowToOrder />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
